require('./bootstrap');

$(document).ready(function(){
    var scroll = $(window).scrollTop();
    if (scroll >= 45) {
        $(".body").addClass("scrolled");
        $(".mainnav").addClass("scrolled");
    }else{
        $(".body").removeClass("scrolled");
        $(".mainnav").removeClass("scrolled");
    }

    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll >= 45) {
            $(".body").addClass("scrolled");
            $(".mainnav").addClass("scrolled");
        }else{
            $(".body").removeClass("scrolled");
            $(".mainnav").removeClass("scrolled");
        }
    });

    $(".sliding-link").click(function(e) {
        e.preventDefault();
        var aid = $(this).attr("href");
        $('html,body').animate({scrollTop: ($(aid).offset().top - 100)},'slow');

        $('.hamburger').removeClass("is-active");
        $('.nav-links').removeClass("show");
        $('body').removeClass("menu-open");
        $('#main-nav').removeClass("menu-open");
    });

    $(".hamburger").click(function(){
        $(this).toggleClass("is-active");
        $('.nav-links').toggleClass("show");
        $('body').toggleClass("menu-open");
        $('#mainnav').toggleClass("menu-open");
    });

    $(".menu-link").click(function(){
        $(".hamburger").removeClass("is-active");
        $('.nav-links').removeClass("show");
        $('body').removeClass("menu-open");
        $('#mainnav').removeClass("menu-open");
    });

    $(".overlay-dark").click(function(){
        $(".hamburger").removeClass("is-active");
        $('.nav-links').removeClass("show");
        $('body').removeClass("menu-open");
        $('#mainnav').removeClass("menu-open");
    });

    $(".has-dropdown").hover(
        function () {
            $(this).find('nav-dropdown').addClass('show');
        }
    );


    var menuItems1 = document.querySelectorAll('li.has-dropdown');
    var timer1, timer2;

    Array.prototype.forEach.call(menuItems1, function(el, i){
        el.addEventListener("mouseover", function(event){
            this.className = "has-dropdown open";
            clearTimeout(timer1);
        });
        el.addEventListener("mouseout", function(event){
            timer1 = setTimeout(function(event){
                var opennav = document.querySelector(".has-dropdown.open");
                opennav.className = "has-dropdown";
                opennav.querySelector('a').setAttribute('aria-expanded', "false");
            }, 1000);
        });
        el.querySelector('a').addEventListener("click",  function(event){
            if (this.parentNode.className == "has-dropdown") {
                this.parentNode.className = "has-dropdown open";
                this.setAttribute('aria-expanded', "true");
            } else {
                this.parentNode.className = "has-dropdown";
                this.setAttribute('aria-expanded', "false");
            }
            event.preventDefault();
        });
        var links = el.querySelectorAll('a');
        Array.prototype.forEach.call(links, function(el, i){
            el.addEventListener("focus", function() {
                if (timer2) {
                    clearTimeout(timer2);
                    timer2 = null;
                }
            });
            el.addEventListener("blur", function(event) {
                timer2 = setTimeout(function () {
                    var opennav = document.querySelector(".has-dropdown.open")
                    if (opennav) {
                        opennav.className = "has-dropdown";
                        opennav.querySelector('a').setAttribute('aria-expanded', "false");
                    }
                }, 10);
            });
        });
    });

});
